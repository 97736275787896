<template>
    <div class="PDeclaration_wrapper">
        <div>
            <header class="title">《投保须知》</header>
            <main class="content" v-html="notice"></main>
        </div>
        <div>
            <header class="title">《投保声明》</header>
            <main class="content" v-html="declaration"></main>
        </div>
    </div>
</template>

<script>
import "./PolicyDeclaration.less";
import { getDeclaration } from "@/api/api";

export default {
    name: "PolicyDeclaration",
    props: ['productId'],
    data() {
        return {
            notice: "", // 投保须知
            declaration: "", // 投保声明
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            const  product  = await getDeclaration({
                pid: this.productId,
            });
            this.notice = product.body.product.ext.productInstructions;
            this.declaration = product.body.product.ext.productDeclaration;
        },
    },
};
</script>
