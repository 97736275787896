<template>
  <div class="customerNotification-wrapper">
    <div>
      <p>
      	尊敬的客户： 根据中国保监会的要求，为了保护您的合法权益，当您通过保险代理公司购买保险产品时，请认真阅读如下内容<br />
      一、公司性质：保险代理机构是经中国保监会批准取得经营保险代理业务许可证，根据保险公司的委托，向保险公司收取代理手续费，在保险公司授权范围内专门代为办理保险业务的单位。&nbsp;<br />
      二、公司基本情况：包括公司名称、住所、法人代表、许可证、工商执照等情况；&nbsp;<br />
      三、业务范围：以许可证所载内容为准；&nbsp;<br />
      四、代理权限；以委托代理合同授权权限为准；&nbsp;<br />
      五、联系方式：&nbsp;&nbsp;<br />
      六、法律责任：&nbsp;<br />
      保险代理机构在保险人授权范围内代理保险业务时，其代理行为所产生的法律责任由保险人承担。&nbsp;<br />
      因授权不明，给他人造成损害的，保险人承担法律责任，保险代理机构承担连带责任。&nbsp;<br />
      保险代理机构不履行代理职责或履行代理合同义务不符合约定，而给被代理人造成损害的，应当依法承担法律责任。<br />
      保险代理机构没有代理权、超越代理权或者代理权终止后已<br />
      被代理人名义从事的保险代理活动，未经被代理人追认的，由保险代理机构承担法律责任。相对人有理由相信保险代理机构由代理权限的，该保险代理活动有效，由保险人承担法律责任。&nbsp;<br />
      七、被代理保险公司的基本情况：列明各家被代理公司的基本情况；&nbsp;<br />
      八、投保提示：&nbsp;<br />
      （一）请查验业务人员的执业证书和相应的身份证明材料，不要接受没有执业证书业务员提供的服务。&nbsp;<br />
      （二）在听取业务人员讲解时，请认真阅读并理解保险条款和相关材料，并重点明确如下几点：&nbsp;<br />
      1、投保险种的名称、投保条件、保险期限和缴纳的保险费；<br />
      2、保险责任；&nbsp;<br />
      3、除外责任；&nbsp;<br />
      （三）投保人和被保险人要如实填写所有应告知的事项，否则，保险公司有权拒绝赔偿或给付保险金；<br />
      （四）投保单要有投保人亲笔签名或加盖公章，不能有业务人员或他人代签。<br />
      （五）如果中途退保，保险公司将按照如下原则进行处理： 1、人寿保险产品：<br />
      （1）签收保单后10日内（选择期）退保，一般情况下，可 以获得所缴纳保险费的全数，但是，有的保险公司需要扣除保单工本费、体检费等费用项目，因此不能全数退保，投保人将承担<br />
      一定数额的保险费损失。&nbsp;<br />
      （2）签收保单10日以后退保，保险公司要按照保险单退保 时所具有的现金价值（即保险条款中列明的现金价值）给投保人退保，投保人要遭受一定的保险费损失，损失的金额与保险单经过的期限长短有关，期限越短损失越大，期限越长损失越小，其中，在购买保险后的2年内退保，保险费损失最大，有时可能损失全部保费。&nbsp;<br />
      2、财产保险产品：&nbsp;<br />
      （1）保险责任开始前，被保险人要求解除合同的，保险公 司退还保险费，但要扣减退保手续费。&nbsp;<br />
      （2）保险责任开始后，被保险人要求解除合同的，保险公 司退还未到期责任部分的保险费。&nbsp;<br />
      &nbsp;3、特殊保险产品需要特别说明的投保提示（按被代理保险公司的要求）；&nbsp;<br />
      九、说明代理手续费的收取方式和比例；&nbsp;<br />
      十、公司业务服务标准；&nbsp;<br />
      十一、其他告知事项；<br />
      	<div>
      		<br />
      	</div>
      </p>
      <p>
      	<br />
      </p>
    </div>
    <div class="customerNotification-button" @click="back">我已阅读完客户告知书</div>
  </div>
</template>

<script>
import './CustomerNotification.less'

export default {
  name: 'CustomerNotification',
  data() {
    return {

    }
  },

  methods: {
    back() {
        this.$emit('closeCustomerNotification');
    }
  }
}
</script>

