<template>
  <div class="ProductDetail_wrapper">
    <img :src="ext.productPicTop" class="banner_img" />
    <div class="insurance-info">
      <div class="left">
        <div class="type">{{ productData.productName }}</div>
        <div class="pingan">{{ productData.comment }}</div>
        <div class="invest">{{productData.productIntro}}</div>
      </div>
      <div class="right">
        <div class="deadline">{{ propertyPremium }}</div>
      </div>
    </div>

    <div class="split"></div>

    <div class="insurance_plan">
      <div class="plan">
        <div class="program">保障方案</div>
      </div>
      <div class="plan_nav">
        <!-- <div
          :class="`nav_item ${currentCase === index ? 'current' : ''}`"
          v-for="(item, index) in productCases"
          :key="item.id"
          @click="handleCaseChange(index)"
        > -->
          {{ productCases.propertyName }}
        <!-- </div> -->
      </div>
      <div class="plan_details" v-if="liabilitys.length > 0">
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item v-for="(item, index) in liabilitys" :key="index" :title="(index + 1) + '.' + item.title " :name="item.id">{{item.content}}</van-collapse-item>

        </van-collapse>

      </div>
    </div>

    <div class="price-container">
      <span class="price">保费</span>
      <span class="num">{{ propertyPremium }}</span>
    </div>

    <div class="more_detail">
      <span @click="goInnerDetail">更多详情</span>
      <span>，请阅读</span>
      <span @click="goDeclaration">《投保须知及声明》</span>和
      <span @click="handlePDF">《条款详情》</span>
    </div>
    <!-- pdf弹出框 -->
    <van-action-sheet v-model="show" title="投保须知及声明" class="action_box">
      <div class="pdf_box">
        <div class="pdf_content"></div>
      </div>
    </van-action-sheet>
    <div class="detail_box">
      <div class="module" ref="module1">
        <img :src="ext.productFeatures" alt @load="handleLoad(1)" />
      </div>
      <div class="module" ref="module2">
        <img :src="ext.productClaim" alt @load="handleLoad(2)" />
      </div>
      <div class="module" ref="module3">
        <img :src="ext.productQuestions" alt @load="handleLoad(3)" />
      </div>
    </div>
    <div class="footerBtn" v-if="isShowFooter == 1">
      <div>
        <span class="icon2 icon-kefu iconfont"></span>
        <span @click="showKeFuFlag = true">客服</span>
      </div>
      <div>{{ propertyPremium }}</div>
      <div @click="showTipPopup">立即投保</div>
    </div>
    <!-- 客服弹出框 -->
    <van-popup v-model="showKeFuFlag" position="bottom" class="kefuPop">
      <div class="kefu_h">
        <a class="kefu_con" href="tel:400-099-6778">
          <span class="kefu_pic"></span>电话咨询
        </a>
      </div>
      <div class="kefu_b" @click="showKeFuFlag = false">取消</div>
    </van-popup>

    <!-- 温馨提示 -->
    <van-popup v-model="showTip" position="bottom" class="tip_pop">
      <p class="tip_pop_title">温馨提示,你即将进入投保流程：</p>
      <p class="tip_pop_content">
        请仔细阅读保险条款、投保须知等内容，为保障你的权益，你在销售页面的操作将会被记录并加密储存。
      </p>
      <a class="tip_pop_link" @click="goCustomerNotification"> 查看客户告知书>> </a>
      <div class="tip_pop_btn" @click="jumpPolicyForm">我知道了</div>
    </van-popup>

    <!-- 《投保须知及声明》、 -->
    <van-popup
      v-model="isPolicyDeclarationPopup"
      position="bottom"
      class="tip_pop"
    >
      <policy-declaration :productId="productId"></policy-declaration>
    </van-popup>
    <!-- 《条款详情》 -->
    <van-popup v-model="isTermsDetailPopup" position="bottom" class="tip_pop">
      <terms-detail :productId="productId"></terms-detail>
    </van-popup>

    <!-- 告知书 -->
    <van-popup v-model="isCustomerNotification" position="bottom" class="tip_pop customerNotification">
      <customer-notification @closeCustomerNotification="closeCustomerNotification"></customer-notification>
    </van-popup>
  </div>
</template>

<script>
import './ProductDetail.less'
import { ActionSheet, Popup,  Collapse, CollapseItem  } from 'vant'
import { getProductDetail } from '../../api/api'
import PolicyDeclaration from './PolicyDeclaration/PolicyDeclaration.vue';
import Termsdetail from './TermsDetail/TermsDetail.vue';
import CustomerNotification from '../CustomerNotification/CustomerNotification.vue';
import { baseConfig } from '../../utils/request'
export default {
  name: 'ProductDetail',
  data() {
    return {
      showKeFuFlag: false, // 展示客服
      showTip: false, //展示温馨提示
      fixedHeight: 0,
      offsetHeight: 0,
      isfixed: false,
      currentNav: 1,
      currentCase: 0,
      show: false,
      offectTopModule1: null,
      offectTopModule2: null,
      offectTopModule3: null,
      imgLoad: [],
      productCases: [],
      productData: {},
      ext: {},
      propertyPremium: '￥0',
      liabilitys: [],
      isPolicyDeclarationPopup: false,
      isTermsDetailPopup: false,
      productId: this.$route.query.productId,
      activeNames: ['1'],
      isCustomerNotification: false,
      isShowFooter: 1
    }
  },
  components: {
    'van-action-sheet': ActionSheet,
    'van-popup': Popup,
    'policy-declaration': PolicyDeclaration,
    'terms-detail': Termsdetail,
    'van-collapse-item': CollapseItem,
    'van-collapse': Collapse,
    'customer-notification': CustomerNotification
  },
  mounted() {
	let loginInfo = localStorage.getItem('loginInfo');
	if (!this.$route.query.sourceType && (!loginInfo || !JSON.parse(loginInfo).openId || !JSON.parse(loginInfo).unitId)) {
	  const url = `${baseConfig.locationHref}`;
	  const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
	  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${oldPath}`
	}else{
		this.getProductDetail()
		window.addEventListener('scroll', this.handleScroll);
	}
    if (this.$route.query.isShowFooter) this.isShowFooter = this.$route.query.isShowFooter;
  },
  methods: {
    goDeclaration() {
     this.isPolicyDeclarationPopup = true;
    },
    showTipPopup() {
      this.showTip = true;
    },
    async getProductDetail() {
      const { productId } = this.$route.query;
      const { planCode } = this.$route.query;
      const { sourceType } = this.$route.query;
      const data = await getProductDetail({
        pid: productId,
        planCode: planCode,
        sourceType: sourceType
      });
      this.productData = data.data;
      this.productData.ext = JSON.parse(data.data.ext);
      this.productData.insurances =JSON.parse(data.data.insurances);
      console.log(this.productData);
      this.ext = data.data.ext || {};
      this.productCases = data.data.insurances.insuranceAmount;
      this.propertyPremium =
       this.productCases.propertyPremium;
      this.liabilitys =
        data.data.insurances.insuranceAmount.liabilitys;
    },
    // 图片加载完毕
    handleLoad(index) {
      this.imgLoad.push(index)
      if (this.imgLoad.length === 3) {
        this.$nextTick(() => {
          // this.fixedHeight = this.$refs.detailNav.offsetTop
          // this.offsetHeight = this.$refs.detailNav.offsetHeight
          this.offectTopModule1 = this.$refs.module1.offsetTop
          this.offectTopModule2 = this.$refs.module2.offsetTop
          this.offectTopModule3 = this.$refs.module3.offsetTop
        })
      }
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop
      this.isfixed = scrollTop > this.fixedHeight
      if (scrollTop >= this.offectTopModule2 - this.offsetHeight) {
        this.currentNav = 2
      } else if (scrollTop >= this.offectTopModule3 - this.offsetHeight) {
        this.currentNav = 3
      } else {
        this.currentNav = 1
      }
    },
    handleCaseChange(index) {
      this.currentCase = index
      this.propertyPremium = this.productData.insurances.insuranceAmount[
        index
      ].propertyPremium
      this.liabilitys = this.productData.insurances.insuranceAmount[
        index
      ].liabilitys
    },
    handleNavClick(id) {
      this.currentNav = id
      this.$nextTick(() => {
        if (id === 1) {
          document.documentElement.scrollTo(
            0,
            this.offectTopModule1 - this.offsetHeight
          )
        }
        if (id === 2) {
          document.documentElement.scrollTo(
            0,
            this.offectTopModule2 - this.offsetHeight
          )
        }
        if (id === 3) {
          document.documentElement.scrollTo(
            0,
            this.offectTopModule3 - this.offsetHeight
          )
        }
      })
    },
    // 展示pdf
    handlePDF() {
      this.isTermsDetailPopup = true
    },
    jumpPolicyForm() {
      const { productData, ext } = this.$data;
      console.log(productData);
      const params = {
        product: productData,
        pid: productData.id,
        appkey: 'dsbjk',
        policy: {
          productRisk: ext.productRisk, //协议
          proname: productData.productName,
          total: productData.lfee,
          deliveryType: 0,
          productId: productData.id,
          clauses: ext.clauses,
        },
        products: [
          {
            insuranceId: productData.insurances.id,
            amount:
              productData.insurances.insuranceAmount
                .propertyValue,
            copys: 1,
            payterm:
              productData.insurances.payPeriod.propertyValue,
            insuranceterm:
              productData.insurances.insurancePeriod
                .propertyValue,
          },
        ],
        productStartTime: ext.productStartTime,
      }
      localStorage.setItem('productInfo', JSON.stringify(params))
      
      const { sourceType } = this.$route.query;
      this.$router.push({
        path: '/policyInfoForm',
        query: {
          productId: this.productData.id,
          planCode: this.$route.query.planCode,
          inviteCode: this.$route.query.inviteCode,
          openId: this.$route.query.openId,
          sourceType: sourceType,
          unitId: this.$route.query.unitId,
		  customerId: this.$route.query.customerId
        },
      })
    },
    // 跳转保障详情
    goInnerDetail() {
      this.$router.push({
        path: './policySchemeDetails',
        query: {
          productId: this.productData.id,
          planCode: this.$route.query.planCode,
          inviteCode: this.$route.query.inviteCode
        }
      })
    },
    goCustomerNotification()
    {
      this.isCustomerNotification = true;
    },
    closeCustomerNotification()
    {
      this.isCustomerNotification = false;

      this.jumpPolicyForm();
    }
  },
}
</script>

