<template>
  <div>
    <a
      class="link_pdf"
      href="javascript:void(0)"
      v-for="item in clauses"
      :key="item.clauseName"
	  @click="openPdf(item.clauseUrl)"
      >{{ item.clauseName }}</a
    >
  </div>
</template>

<script>
import { getTermsDetail } from '@/api/api'
import { baseConfig } from '../../../utils/request'
import wx from 'weixin-js-sdk'
export default {
  name: 'TermsDetail',
  props: ['productId'],
  data() {
    return {
      // 条款列表
      clauses: []
    }
  },
  methods: {
    async getPdfs() {
      const  product  = await getTermsDetail({
        pid: this.productId
      })
      // const { ext: { clauses } } = product.body.product
      this.clauses = product.body.product.ext.clauses
    },
    openPdf(url) {
		var ua = window.navigator.userAgent.toLowerCase();
		//先判断是否微信浏览器
		if (ua.match(/MicroMessenger/i) == "micromessenger") {
		    //再判断一下是否在小程序里
		    if (/(Android)/i.test(navigator.userAgent)) {
		    	wx.miniProgram.getEnv((res) => {
					if(res.miniprogram){
						wx.miniProgram.navigateTo({
							url:`../../../newIndex/downloadpdf/downloadpdf?url=${encodeURIComponent(url)}`
						})
					}else{
						location.href = url
					}
		    		
		    	})
		    }else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
		    	location.href = url
		    }else{
		    	location.href = url
		    }
		}else{
		   	location.href = url
		}
    }
  },
  created() {
    this.getPdfs()
  }
}
</script>

<style lang="less" scoped>
.link_pdf {
  display: block;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333;
  border-bottom: 0.02rem solid #ccc;
  padding-left: 0.2rem;
  box-sizing: border-box;
  font-size: 0.28rem;
}
</style>
